import { className } from '@empori/util';
import * as React from 'react';
import { FaIcon } from './Icons';

export function LoadingSkeleton() {
  return <div className='empori-loading-skeleton'>
    <FaIcon clsName='fa-light fa-spinner-third' spin />
  </div>;
}

interface UlitityWindowProps {
  message: string | React.ReactNode;
  backoffice?: boolean;
  textBold?: boolean;
}

export function ErrorWindow(props: UlitityWindowProps) {
  return <div className={`empori-error ${props.textBold ? 'error-bold' : ''}`}>
    <FaIcon clsName={props.backoffice ? 'fa-regular fa-warning' : 'fa-regular fa-circle-exclamation'} />
    {props.message}
  </div>;
}

export function SuccessWindow(props: UlitityWindowProps) {
  return <div className='empori-success'>
    <FaIcon clsName='fa-regular fa-circle-check' />
    {props.message}
  </div>;
}

export function ProcessingWindow(props: UlitityWindowProps) {
  return <div className='empori-processing'>
    <p>
      <FaIcon clsName='fa-regular fa-spinner-third' spin />
      {props.message}
    </p>
  </div>;
}

interface ContentEmptyWindowProps extends UlitityWindowProps {
  height?: number;
}

export function ContentEmptyWindow(props: React.PropsWithChildren<ContentEmptyWindowProps>) {
  return <div className='empori-content-empty' style={{ height: props.height ? props.height : 200 }}>
    <p>{props.message}</p>
    {props.children}
  </div>;
}

interface ActionButtonProps {
  label: string;
  inverted?: boolean;
  action: () => void;
}

export function ActionButton(props: ActionButtonProps) {
  return <button className={className('empori-button', { inverted: props.inverted })}
    onClick={() => props.action()}
    title={props.label}>
    {props.label}
  </button>;
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);