const faFaSrc = 'https://static.empori.se/fa/css/all.min.css';
let faLoaded = false;

function MountFontAwesomePro() {
  // Only used for FontAwesome in the back-office.
  const faFa = document.createElement('link');
  faFa.href = faFaSrc;
  faFa.rel = 'stylesheet';
  document.head.appendChild(faFa);
}

export function InitFontAwesome() {
  if (faLoaded) {
    return;
  }

  const links = document.getElementsByTagName('link');

  for (let i = links.length; i--;) {
    if (links[i].href.includes(faFaSrc)) {
      return;
    }
  }

  MountFontAwesomePro();
  faLoaded = true;
}